import React, { useRef } from 'react';

import Container from '../components/Container';
import Hero from '../components/Hero';
import Banner from '../components/Banner';
import ThemeLink from '../components/ThemeLink';
import Layout from '../components/Layout/Layout';

import * as styles from './shenclinic.module.css';
import Button from '../components/Button/Button';
const ShenclinicPage = (props) => {
  let aboutRef = useRef();
  let infertilityRef = useRef();
  let drshenRef = useRef();

  const handleScroll = (elementReference) => {
    if (elementReference) {
      window.scrollTo({
        behavior: 'smooth',
        top: elementReference.current.offsetTop - 280,
      });
    }
  };

  return (
    <Layout disablePaddingBottom title="Shen Clinic">
      <div className={styles.root}>
        {/* Hero Container */}
        <Banner
          maxWidth={'900px'}
          bgImage={'/background/poppy_flower.jpg'}
          height={'300px'}
          name={`Shen Clinic \n `}
          color={'var(--standard-white)'}
        />

        <div className={styles.navContainer}>
          <ThemeLink onClick={() => handleScroll(aboutRef)} to={'#about'}>
            About Shen Clinic
          </ThemeLink>

          <ThemeLink onClick={() => handleScroll(drshenRef)} to={'#drshen'}>
            About Dr. Shen
          </ThemeLink>
        </div>

        {/* <div className={styles.imageContainer}>
          <img alt={'shirt brand'} src={'/about1.png'}></img>
        </div> */}
        <Container size={'large'} spacing={'min'}>
          <div className={styles.content}>
            <h3>About Shen Clinic</h3>
            <div ref={aboutRef}>
              <p>
                Shen Clinic offers leading edge complementary and alternative
                medicine to support our patients on their path to wellness and
                encouraging our patients to heal on all levels.
              </p>
              <p>
                Based on many years of experience in both conventional and
                complementary medicine, Dr. Shen uses a variety of assessment
                techniques to evaluate some of the best treatment options
                available for you.
              </p>
              <p>
                Dr. Shen’s passion for medical research and alternative
                treatments include therapies and medicine from China, America,
                Japan, Mexico and wherever medical pioneers are making
                breakthroughs in the treatment of various chronic medical
                conditions.
              </p>
              <h4>Our Services include:</h4>

              <ol>
                <li>Chinese Herbal Medicine Consultation</li>
                <li>Acupuncture</li>
                <li>Nutrition & Vitamin Consultation</li>
                <li>Consultation for Homeopathic Remedies</li>
                <li>Cupping Therapies</li>
                <li>Massage (Tui Na)</li>
                <li>Infertility Treatments</li>
              </ol>

              <Button level={'secondary'} href="/services">
                Learn more about our Services
              </Button>

              <br />

              <br />
              <p>
                Call us at <a href="tel:310-515-6062">(310) 515-6062</a> to make
                an appointment.
              </p>
              <div className={styles.horizontal}>
                <img
                  alt={'Shen Clinic treatment'}
                  src={'/shenclinic/shenclinicvideo.jpg'}
                ></img>
              </div>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/9gbQjLx-pKA"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; allowfullscreen"
                allowFullScreen
              ></iframe>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/XKc5RqHRxJE"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; allowfullscreen"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className={styles.content}>
            <h3>About Dr. Xiaoxiong Shen, PhD, LAc</h3>
            <div id={'drshen'} ref={drshenRef}>
              <p>
                Dr. XIAOXIONG SHEN is one of the most highly educated
                practitioners in the field of Herbal Medicine and Acupuncture.
                He received his received his Master's degree in Traditional
                Chinese Medicine (TCM) from Nanjing University of Chinese
                Medicine in 1987. In 1997, he earned his Ph.D at Mie University
                Medical School in Japan. Dr. Shen has received postdoctoral
                fellowship training from Tokyo University School of Medicine in
                Japan and Vanderbilt University School of Medicine in Nashville,
                TN.
              </p>
              <p>
                Dr. SHEN is among the top professionals in both Western and
                Oriental Medicine fields. He has published approximately 40
                peer-reviewed scientific papers in the top academic journals of
                both Western and Oriental Medicine such as:{' '}
                <i>
                  Journal of Molecular Endocrinology, American Journal of
                  Physiology, American Journal of Obstetrics and Gynecology, The
                  Journal of Traditional Chinese Medicine
                </i>
                . He has also contributed chapters to at least 5 scientific
                books . He is often invited to present guest lectures in many
                countries, such as Japan, China, Singapore, Canada, Europe, and
                the United States. He was formerly a Professor and Director at
                South Baylo University School of Acupuncture and Oriental
                Medicine in Anaheim, CA.
              </p>
              <p>
                Dr. SHEN is a well-trained reproductive field specialist as well
                as a renowned infertility and gynecology pioneer. He has
                contributed to a variety of scientific papers as well as
                research data on the treatment of infertility. His approach
                focuses on the use of herbal medicine and acupuncture.
              </p>
              <div className={styles.horizontal}>
                <img
                  alt={'Dr. Shen at a conference speaking'}
                  src={'/drshen/drshen_conference.jpg'}
                ></img>
                
              </div>
              <br/>
              <div className={styles.horizontal}>
              <img
                  alt={'Dr. Shen Certificate'}
                  src={'/drshen/drshen_certificate.jpeg'}
                ></img>
              </div>
              <u>
                <h4>PUBLICATIONS 出版著作:</h4>
              </u>

              <h4>BOOKS 著作:</h4>
              <ol>
                <li>
                  图解中药功效异同与运用
                  <br />
                  人民卫生出版社 2022.5 <br />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.amazon.com/gp/product/7117330112/ref=cx_skuctr_share?smid=ANMZHBE0EMYCX"
                  >
                    Buy on Amazon
                  </a>
                </li>
                <a
                  href="https://www.amazon.com/gp/product/7117330112/ref=cx_skuctr_share?smid=ANMZHBE0EMYCX"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/products/book.jpg" alt="Dr. Shen Book 图解中药功效异同与运用" />
                </a>
                <li>
                  不孕症疑难验案专家剖析实录 <br />
                  中国中医药出版社2020. 9
                </li>
                <li>
                  The Treatment of Pain with Chinese Herbs and Acupuncture
                  <br />
                  痛症的中医药针灸治疗 (英文)
                  <br />
                  Harcourt Health Sciences Press, UK. 2002.1
                </li>
                <li>
                  妇科医籍辑要从书 胎产科病证
                  <br />
                  中国中医药出版社 2019. 9
                </li>
                <li>
                  中国补品 <br />
                  中医古籍出版社 1993.3
                </li>
                <li>
                  补养指南 <br />
                  中国医药科技出版社1994.2
                </li>
              </ol>

              <h4>PAPERS： 发表主要论著: </h4>
              <ol>
                <li>
                  <a
                    href="https://www.heraldopenaccess.us/openaccess/advanced-maternal-age-with-severely-diminished-ovarian-reserve-with-multiple-successful-live-births-after-chinese-herbal-medicine-treatment-a-case-report"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Advanced Maternal Age with Severely Diminished Ovarian
                    Reserve with Multiple Successful Live Births after Chinese
                    Herbal Medicine Treatment: A Case Report
                  </a>
                  <br />
                  Journal of Reproductive Medicine Gynaecology & Obstetrics
                  March 1, 2022
                </li>
                <li>
                  The Limitation of Randomized Control Trials on the Influence
                  of Acupuncture and In Vitro Fertilization: A Literature Review{' '}
                  <br />
                  MEDICAL ACUPUNCTURE 2022,34(5):248-250
                </li>
                <li>
                  Acupuncture and Pregnancy: Classical Meets Modern <br />
                  MEDICAL ACUPUNCTURE 2019,31(1):24-33
                </li>
                `
                <li>
                  个性化医疗：中医`走向世界的契机 <br />
                  《中医药导报》2019,25(4):1-5
                </li>
                <li>
                  中药在海外发展方向 <br />
                  《中医药导报》2019, 25 (1):1
                </li>
                <li>
                  中医可以发表阴性结果论文吗?
                  <br />
                  《中医药导报》2018,24(15):1-3
                </li>
                <li>
                  阴阳学说:一个风靡现代医学界的科学概念 <br />
                  《中医药导报》2018,24(4):1-6
                </li>
                <li>
                  针刺治疗多囊卵巢综合征的海外研究进展 <br />
                  《中医药导报》2017,23(12):1-4
                </li>
                <li>
                  美国中医教育访谈录（1） <br />
                  《中医药导报》2017,23(14):1-5
                </li>
                <li>
                  美国中医教育访谈录（2） <br />
                  《中医药导报》2017,23(19):7-13
                </li>
                <li>
                  针刺辅助干预试管婴儿－胚胎移植前后的海外临床进展 <br />
                  《中国中西医结合杂志》2017,37(12):1515-1519
                </li>
                <li>
                  中医妇科在海外的发展回顾与展望 <br />
                  《中医药导报》2016,22(22):1-6
                </li>
                <li>
                  中医针灸在海外的发展现状特点 <br />
                  《环球中医药》2015,8(5):568-571
                </li>
                <li>
                  阴阳学说在现代医学中的运用与回顾 <br />
                  《环球中医药》2011,4(2):120-124
                </li>
                <li>
                  中医英译中的”简”与”繁” <br />
                  《中国中西医结合杂志》2007,27(9):851-852
                </li>
                <li>
                  Cellular co-localization and co-regulation between
                  hypothalamic pro-TRH and prohormone convertases in
                  hypothyroidism. <br />
                  《美国生理学杂志.内分泌代谢》Am J Physiol Endocrinol Metab.
                  2007 Jan;292(1):E175-86. 英文
                </li>
                <li>
                  Thyroid hormones selectively regulate the posttranslational
                  processing of prothyrotropin-releasing hormone in the
                  paraventricular nucleus of the hypothalamus. <br />
                  《内分泌学》Endocrinology. 2006 Jun;147(6):2705-16. 英文
                </li>
                <li>
                  Regulation of regional expression in rat brain PC2 by thyroid
                  hormone/characterization of novel negative thyroid hormone
                  response elements in the PC2 promoter <br />
                  《美国生理学杂志.内分泌代谢》Am J Physiol Endocrinol Metab.
                  2005,288:E236-E245 英文
                </li>
                <li>
                  Thyroid hormone regulation of prohormone convertase 1 (PC1)
                  characterization of negative thyroid hormone response elements
                  and studies in rat brain <br />
                  《分子内分泌学杂志》J Mol Endocrinol. 2004 Aug;33(1):21-33.
                  英文
                </li>
                <li>
                  Role of prohormone convertase 1 in diabetes: studies using
                  transgenic mice
                  <br />
                  《研究医学杂志》Journal of Investigative Medicine. 2004 52
                  (1):86 英文
                </li>
                <li>
                  Prohormone convertase 2 enzymatic activity and its regulation
                  in neuro-endocrine cells and tissues <br />
                  《多肽调节》Regulatory Peptides. 2003; 110:197-205 英文
                </li>
                <li>
                  Comparison of piezo-assisted micromanipulation with
                  conventional micromanipulation for intracytoplasmic sperm
                  injection into human oocytes <br />
                  《妇产科研究》Gynecologic and obstetric investigation.2001;
                  52(3):158-162
                </li>
                <li>
                  In vitro fertilization and intracytoplasmic sperm injection
                  for couples with unexplained infertility after failed direct
                  intraperitoneal insemination <br />
                  《辅助生殖和遗传学杂志》Journal of assisted reproduction and
                  genetics. 2000; 17(9): 515-520
                </li>
                <li>
                  Expression of glucose transporter 4 mRNA in adipose tissue and
                  skeletal muscle of ovariectomized rats treated with sex
                  steroid hormones <br />
                  《生命科学》Life Science. 2000; 66(7):641-648 英文
                </li>
                <li>
                  GnRH agonist. Increasing the pregnancy rate after combined
                  treatment with hMG/hCG and direct intraperitoneal insemination{' '}
                  <br />
                  《生殖医学杂志》Journal of Reproductive Medicine. 1999;
                  44(1):18-22 英文
                </li>
                <li>
                  Changes in ovarian expression of tissue-type plasminogen
                  activator and plasminogen activator inhibitor type-1 messenger
                  ribonucleic acids during ovulation in rat <br />
                  《内分泌学杂志》Endocrine Journal.1997; 44: 341英文
                </li>
                <li>
                  Gene expression of GLUT4 in rats treated with 17
                  beta-estradiol and progesterone. <br />
                  DIABETES. 1997; 46:1440
                </li>
                <li>
                  Hexokinase II activity and mRNA abundance in adipose tissue
                  and skeletal muscle during pregnancy and lactation in rats{' '}
                  <br />
                  《美国妇产科学杂志》American Journal of Obstetrics and
                  Gynecology. 1997;176: 651 英文
                </li>
                <li>
                  Expression or GLUT4 mRNA in the skeletal muscle and adipose
                  tissue from rats treated with progesterone and 17 beta center
                  dot estradiol <br />
                  DIABETOLOGIA. 1996; 39: 788
                </li>
                <li>
                  Effect of GnRHa/hMG/hCG/DIPI Method upon unexplain
                  infertility. <br />
                  《產婦人科实际》 1997;46(2):261 日文
                </li>
                <li>
                  细胞周期调节与中医阴阳学说 <br />
                  《北京中医药大学学报》 1997; (5):45
                </li>
                <li>
                  月经太极图说 <br />
                  《北京中医药大学学报》 1996; (7):23
                </li>
                <li>
                  中药保守治疗宫外孕42例临床观察 <br />
                  《江苏中医药杂志》1995;16(2):19
                </li>
                <li>
                  Tissue Specific Transcription Factors Involved in the
                  decidualization of the endometrial stromal cells -POU Domain
                  Gene Family- <br />
                  《產婦人科治療》1994; 69(4):466 日文
                </li>
                <li>
                  中医治疗原发性痛经的现状 <br />
                  《上海中医药杂志》1989; (4):41
                </li>
                <li>
                  太极阴阳理论与月经周期调节 <br />
                  《上海中医药杂志》1988; (3):2
                </li>
                <li>
                  《傅青主女科》补肾六法 <br />
                  《浙江中医学院学报》 1988; 11(1):3
                </li>
                <li>
                  张锡纯调冲脉治妇科病的经验探讨 <br />
                  《浙江中医学院学报》 1988; 11(1):3
                </li>
                <li>
                  复坤汤治疗无排卵月经疾患的临床观察及实验研究 <br />
                  《中医杂志》 1988;29(2):40
                </li>
                <li>
                  Samenstelling en dosering van "Fu Kun Tang" <br />
                  《比利时中医药杂志》 1992;(9): 32 荷兰文
                </li>
                <li>
                  Klinisch en Experimenteel Onderzoek bij de T.C.G.-behandeling
                  van Vrouwelijke Steriliteit t.g.v.Anovulatoire Cycli <br />
                  《比利时中医药杂志》 1992;(7): 33 荷兰文
                </li>
                <li>
                  复坤汤治疗无排卵月经疾患的临床观察及实验研究 <br />
                  《中医杂志》 日文版 1988;15(5):32 日文
                </li>
                <li>
                  中医妇产科学自学要点 <br />
                  《中医教学》 1988; (3): 20
                </li>
                <li>
                  脐流黄水伴经期出血治验 <br />
                  《上海中医药杂志》1987; (3):14
                </li>
              </ol>
            </div>
          </div>
        </Container>

        {/* <div className={styles.imageContainer}>
          <img alt={'shirt backwards'} src={'/about3.png'}></img>
        </div> */}
      </div>
    </Layout>
  );
};

export default ShenclinicPage;
